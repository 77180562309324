/*
    Хранилищие функций для scripts.js (применяются на всем сайте)
 */
(function() {
    window.SVK = window.SVK || {};
    SVK.$w = $(window);
    SVK.$b = $('body');
})();

/**
 * breakpoint
 * */
SVK.xxl = 1960;
SVK.xl = 1380;
SVK.lg = 1240;
SVK.lgs = 1024;
SVK.md = 768;
SVK.sm = 320;

